.block {
  display: flex;
  flex-direction: column;
  background-image: url("../images/develop_bg/Bitmap.jpg");
  width: 100%;
  height: 100vh;

  &__header {
    height: 100%;
    text-align: center;
    font-size: 50px;
    text-transform: uppercase;
  }

  &__header-phact {
    margin-top: 161px;
    margin-bottom: 44px;
  }

  &__header-title {
    color: white;
  }

  &__header-text-green {
    color: #00D47D;
  }

  &__footer {
    display: flex;
    padding-bottom: 70px;
    justify-content: center;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    position: relative;
    svg{
      .chars {
        transition: 0.4s all;
      }

    }

    &:hover {
      svg {
        .chars {
          fill: white;
        }
      }

      .block__text {
        border-bottom: 1px solid #00D47D;
        color: #00D47D;
      }

      .block__link-png {
        opacity: 0;
      }

      .block__link-png_hover {
        opacity: 1;
      }
    }

    &:first-child {
      margin-right: 45px;
    }
  }

  &__link-png {
    width: 127px;
    height: 31px;
    z-index: 1;
    transition: all 0.4s;

    &_hover {
      opacity: 0;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 2;
    }
  }

  &__text {
    border-bottom: 1px solid white;
    color: white;
    transition: 0.4s all;
  }
}